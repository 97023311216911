import React from 'react';
import './our-services.css';

function Services() {
    return (
        <section className="services" id='services'>
            <div className="wrapper services">
                <h1>Our Services</h1>
                <div className="wrapper-cards">
                    <div className="card">
                        <h2>Coal Sizing</h2>
                        <p>Our coal sizing services are at the heart of our commitment to delivering excellence. Since the establishment of our coal sizing plant in 2011, we have been providing customized sizing solutions that meet the highest standards of quality and precision. Our advanced equipment and expert team ensure that each particle is processed with the utmost care, resulting in coal that perfectly matches your specifications and performance requirements.</p>
                    </div>
                    <div className="card">
                        <h2>Transportation</h2>
                        <p>Energy Corporation takes pride in offering seamless coal transport solutions that ensure your energy needs are met without interruption. Leveraging our 20 years of industry experience, we have developed a robust logistics network capable of delivering coal safely and efficiently, no matter the destination.</p>
                    </div>
                </div>

                <a href='#contact-us'><button className='quote'>Get a Quote</button></a>

            </div>
        </section>
    )
}

export default Services