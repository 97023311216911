import React from "react";
import './contact-us.css';
import { useForm, ValidationError } from '@formspree/react';

function Contact() {
    const [state, handleSubmit] = useForm("mpzvqryn");

    return (
        <section className="contact-us" id="contact-us">
            <div className="wrapper contact-us">
                <h1>Contact Us</h1>
                <div className="wrap-form">
                    {state.succeeded ? (<div className="submitted" style={{ margin: "auto", }}>
                        Submitted!
                    </div>) : (
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Name: </label>
                            <input type="text" id="name" required name="name" />
                            <label htmlFor="email">Email: </label>
                            <input type="email" id="email" name="email" required />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                            <label htmlFor="subject">Subject: </label>
                            <input type="text" id="subject" name="subject" required />
                            <label htmlFor="textbox">Message: </label>
                            <textarea
                                name="message"
                                id="textbox"
                                placeholder="Enter text here"
                                defaultValue={""}
                            />
                            <div className="submitted"></div>
                            <button type="submit">Send</button>
                        </form>)}
                </div>
            </div>
        </section>
    );

};

export default Contact;