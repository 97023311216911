import React from 'react'
import { useState } from 'react';
function Header() {

    const [isNavVisible, setIsNavVisible] = useState(false);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    const scrollToSection = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const targetId = event.currentTarget.getAttribute("href"); // Get target section ID
        const targetPosition = document.querySelector(targetId).offsetTop; // Get target position
        const offset = 70; // Height of your sticky header, adjust as needed
        const scrollTarget = targetPosition - offset; // Calculate scroll position with offset

        window.scrollTo({
            top: scrollTarget,
            behavior: 'smooth'
        });
    };

    return (
        <header>
            <div className="container">
                <img src='images/logo3.jpg' alt='logo' />
                <div className='hamburger-menu' onClick={toggleNav}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </div>
                <nav className={isNavVisible ? "nav-visible" : ""}>
                    <ul className="nav-bar">
                        <li>
                            <a href="#home" onClick={scrollToSection}>Home</a>
                        </li>
                        <li>
                            <a href="#about-us" onClick={scrollToSection}>About Us</a>
                        </li>
                        <li>
                            <a href="#services" onClick={scrollToSection}>Services</a>
                        </li>
                        <li>
                            <a href="#gallery" onClick={scrollToSection}>Gallery</a>
                        </li>
                        <li>
                            <a href="#contact-us" onClick={scrollToSection}>Contact Us</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header