import React, { useState, useEffect } from 'react';
import './gallery.css';

function Gallery() {
    const photos = ['images/4.jpg', 'images/2.jpg', 'images/3.jpg', 'images/5.jpg', 'images/1.jpg'];
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    const nextPhoto = () => {
        setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
    };

    const prevPhoto = () => {
        setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovering) {
                nextPhoto();
            }
        }, 3000); // Change photo every 3 seconds, adjust as needed
        return () => clearInterval(interval);
    });

    

    return (
        <section id='gallery'>
            <h1>Gallery</h1>
            <div className="photo-switcher-container" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                {photos.map((photo, index) => (
                    <img
                        key={photo}
                        src={photo}
                        alt={`Displayed ${index}`}
                        className={`photo-switcher-img ${index === currentPhotoIndex ? 'active' : ''}`}
                    />
                ))}
                <div className="photo-switcher-navigation">
                    <button className="photo-switcher-button" onClick={prevPhoto}>&lt; Prev</button>
                    <button className="photo-switcher-button" onClick={nextPhoto}>Next &gt;</button>
                </div>
            </div>
        </section>
    );
}

export default Gallery;

