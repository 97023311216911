import Footer from './components/Footer/footer';
import './App.css';
import Contact from './components/Contact-Us/contact-us';
import Services from './components/our-services/our-services';
import Header from './components/Header/header';
import Gallery from './components/Gallery/gallery';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <section className="home" id='home'>
          <div className="wrapper coal">
            <div className="floater">
              <h1>Energy Corporation</h1>
              <p>
                Powering Progress with Precision. At Energy Corporation, we do more than just extract coal; we craft energy solutions with meticulous attention to detail. Our plant, powered by the latest in coal sizing technology, reflects our commitment to precision, empowering clients to achieve their operational goals effectively and efficiently.
              </p>
            </div>
          </div>
        </section>
        <section className="about-us" id='about-us'>
          <h1>About Us</h1>
          <div className="wrapper about-us">
            <p>
              For two decades, Energy Corporation has stood as a pillar of reliability in coal supply and processing. Established 20 years ago, our journey has been one of continuous growth and innovation. The inauguration of our modern coal sizing plant in 2011 allowed us to offer customized coal grades, ensuring optimal performance for our clients' operations. We pride ourselves on our enduring legacy, industry expertise, and our dedication to supporting the energy needs of businesses nationwide.
            </p>
            <div className="whyus">
              <h2>Why Us?</h2>
              <p>
                At Energy Corporation, we stand out from the crowd for several key reasons. We've spent the last 20 years perfecting our craft, ensuring that we not only meet but exceed industry standards. Our coal sizing plant represents the pinnacle of our innovation, offering bespoke coal specifications that precisely fit your needs. When you partner with us, you're choosing a company that prioritizes sustainability, delivers exceptional quality, and provides a level of service that is unmatched in the industry.
              </p>
            </div>

          </div>
        </section>
        <Services></Services>
        <Gallery></Gallery>
        <Contact></Contact>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
