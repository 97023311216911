import React from 'react';
import './footer.css'; 

function Footer() {
    const scrollToSection = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const targetId = event.currentTarget.getAttribute("href"); // Get target section ID
        const targetPosition = document.querySelector(targetId).offsetTop; // Get target position
        const offset = 70; // Height of your sticky header, adjust as needed
        const scrollTarget = targetPosition - offset; // Calculate scroll position with offset

        window.scrollTo({
            top: scrollTarget,
            behavior: 'smooth'
        });
    };

    return (
        <footer className="footer">
            <div className="footer-container-global">
                <div className="footer-container-main">
                    <div className="footer-section about">
                        <h5>About Energy Corporation</h5>
                        <p>Leading provider of coal sizing and transportation services.</p>
                    </div>
                    <div className="footer-section contact">
                        <h5>Contact Us</h5>
                        <p>Survey No 17/2, Chikhalgaon, Kalamana Road(Near Kartikay Coal Washeries),<br/>
                           Wani, Dist Yavatmal - 445 304 <br/>
                           Maharashtra, India</p>
                        <p>Phone: +91 9850332741 </p>
                        <p>Email: energycorporationwani@gmail.com</p>
                    </div>
                    <div className="footer-section links">
                        <h5>Quick Links</h5>
                        <a href="#services" onClick={scrollToSection}>Our Services</a>
                        <a href="#gallery" onClick={scrollToSection}>Gallery</a>
                        <a href="#contact-us" onClick={scrollToSection}>Contact Us</a>
                    </div>
                </div>
                <div className="footer-container-bottom">
                    <p> 2024 © Energy Corporation</p>
                </div>

            </div>

        </footer>
    );
}

export default Footer;
